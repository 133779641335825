import { gql, QueryResult, useQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect } from 'react';
import { AuthenticatedUserQuery } from '@pv/common/graphql/operations';
import Smartlook from 'smartlook-client';

type User = AuthenticatedUserQuery['authenticatedUser'];

const smartlookEnabled = process.env.REACT_APP_PV_ENV === 'production';
const smartlookProjectKey = 'ef6ac9623d6da8a00be72692b0553dd1a1c72ca3';

const userQuery = gql`
  query AuthenticatedUser {
    authenticatedUser {
      id
      phone
      email
      firstName
      lastName
      fullName
      image
      signatureBlock
      connectedGoogleCalendar
      showLeadsOnGoogleCalendar
      createdAt
      role
      merriConnected
      prismmConnected
      sevenShiftsConnected
      organizationId
      organization {
        id
        canUpdate {
          value
        }
        canManageBilling {
          value
        }
        canCancelEvents {
          value
        }
        canManageExpressBookRequests {
          value
        }
        name
        billingEmail
        stripeCustomerId
        stripeLast4
        freeTrialEndsAt
        image
        banner
        contactFormBackgroundColor
        helloLeadFormPath
        helloLeadFormUrl
        reserveConnected
      }
      organizationMembership {
        id
        role
        canUpdate {
          value
        }
        canDestroy {
          value
        }
        newLeadsEmail
        newLeadsSms
        paymentEmails
        teamMessageEmail
        initialMember
      }
      organizationMemberships {
        id
        role
        canUpdate {
          value
        }
        canDestroy {
          value
        }
        newLeadsEmail
        newLeadsSms
        paymentEmails
        teamMessageEmail
        organization {
          id
          canUpdate {
            value
          }
          canManageBilling {
            value
          }
          name
          billingEmail
          stripeCustomerId
          stripeLast4
          freeTrialEndsAt
          image
          banner
          contactFormBackgroundColor
          helloLeadFormPath
          helloLeadFormUrl
        }
      }
      activeVenueMemberships {
        id
        venue {
          id
          name
          internalName
          merriId
          sevenShiftsId
          prismmId
          reserveRestaurantId
        }
      }
    }
  }
`;

interface AuthContext {
  user: User | null;
}

interface UserContext extends AuthContext {
  freeTrialEndsAt: Date | null;
  refetchAuthenticatedUser: QueryResult['refetch'];
}
const AuthenticatedUserContext = createContext<UserContext>({
  user: null,
  freeTrialEndsAt: null,
  refetchAuthenticatedUser: () => false as any,
});
export const useUserAuth = () => useContext(AuthenticatedUserContext);

interface ProviderProps {
  children: JSX.Element;
}

export const AuthUserProvider = ({ children }: ProviderProps) => {
  const { data, refetch, loading } =
    useQuery<AuthenticatedUserQuery>(userQuery);

  const user = data?.authenticatedUser;

  useEffect(() => {
    if (smartlookEnabled && user) {
      Smartlook.init(smartlookProjectKey);
      const userDetails = {
        name: user.fullName,
        email: user.email,
      };
      Smartlook.identify(user.id, userDetails);
    }
  }, [user]);

  if (loading) {
    return null;
  }

  const organization = user?.organization;

  const freeTrialEndsAt = organization?.freeTrialEndsAt;
  const value = {
    user,
    freeTrialEndsAt,
    refetchAuthenticatedUser: refetch,
  };

  return (
    <AuthenticatedUserContext.Provider value={value}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};
