import React from 'react';
import { useTheme } from '@material-ui/styles';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  HelpCircle,
  Gift,
  ThumbsUp,
  Video,
  Smartphone,
  Mail,
  PhoneCall,
} from 'react-feather';

const HelpList = ({
  afterClick,
  isMobile,
}: {
  afterClick?: () => void;
  isMobile?: boolean;
}) => {
  const theme = useTheme();

  const onCallSupportNowClick = () => {
    if (afterClick) {
      afterClick();
    }
    alert('Call or Text (415) 906-4190 for assistance');
  };

  const openAndClose = (path: string) => () => {
    window.open(path);
    if (afterClick) {
      afterClick();
    }
  };

  return (
    <List>
      <ListItem
        button
        onClick={openAndClose(
          'https://support.perfectvenue.com/articles/913910-training-videos'
        )}
      >
        <ListItemIcon>
          <Video style={{ color: theme.palette.primary.main }} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          style={{
            paddingTop: '4px',
            paddingBottom: '4px',
            fontSize: '16px',
            color: theme.palette.primary.main,
            paddingLeft: '8px',
          }}
        >
          Training Video
        </ListItemText>
      </ListItem>
      <ListItem
        button
        onClick={openAndClose('https://perfectvenue.canny.io/feature-requests')}
      >
        <ListItemIcon>
          <ThumbsUp style={{ color: theme.palette.primary.main }} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          style={{
            paddingTop: '4px',
            paddingBottom: '4px',
            fontSize: '16px',
            color: theme.palette.primary.main,
            paddingLeft: '8px',
          }}
        >
          Feedback
        </ListItemText>
      </ListItem>
      <ListItem
        button
        onClick={openAndClose('https://form.typeform.com/to/vNu4kAfJ')}
      >
        <ListItemIcon>
          <Gift style={{ color: theme.palette.primary.main }} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          style={{
            paddingTop: '4px',
            paddingBottom: '4px',
            fontSize: '16px',
            color: theme.palette.primary.main,
            paddingLeft: '8px',
          }}
        >
          Refer a Friend
        </ListItemText>
      </ListItem>

      <Divider />
      <ListItem
        button
        onClick={openAndClose('https://support.perfectvenue.com/')}
      >
        <ListItemIcon>
          <HelpCircle style={{ color: theme.palette.primary.main }} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          style={{
            paddingTop: '4px',
            paddingBottom: '4px',
            fontSize: '16px',
            color: theme.palette.primary.main,
            paddingLeft: '8px',
          }}
        >
          Help Center
        </ListItemText>
      </ListItem>
      {isMobile ? (
        <ListItem component="a" href="tel:4159064190">
          <ListItemIcon>
            <PhoneCall style={{ color: theme.palette.primary.main }} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            style={{
              paddingTop: '4px',
              paddingBottom: '4px',
              fontSize: '16px',
              color: theme.palette.primary.main,
              paddingLeft: '8px',
            }}
          >
            Call Support
          </ListItemText>
        </ListItem>
      ) : (
        <ListItem button onClick={onCallSupportNowClick}>
          <ListItemIcon>
            <PhoneCall style={{ color: theme.palette.primary.main }} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            style={{
              paddingTop: '4px',
              paddingBottom: '4px',
              fontSize: '16px',
              color: theme.palette.primary.main,
              paddingLeft: '8px',
            }}
          >
            Call Support
          </ListItemText>
        </ListItem>
      )}
    </List>
  );
};

export default HelpList;
